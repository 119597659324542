// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-conseils-js": () => import("./../../../src/pages/conseils.js" /* webpackChunkName: "component---src-pages-conseils-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legals-js": () => import("./../../../src/pages/mentions-legals.js" /* webpackChunkName: "component---src-pages-mentions-legals-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-plan-du-site-js": () => import("./../../../src/pages/plan-du-site.js" /* webpackChunkName: "component---src-pages-plan-du-site-js" */),
  "component---src-pages-politique-confidentialite-js": () => import("./../../../src/pages/politique-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-confidentialite-js" */)
}

